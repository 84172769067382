.bottom {
    padding: 78px 0;
    text-align: center;
}
.btnWrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.4;
    align-items: center;
}
.link {
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--primary-link-color);
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    margin: 20px 20px 0;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
    &:hover {
        color: var(--primary-link-hover-color);
    }
}
