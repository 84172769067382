.intro {
    padding-bottom: 25px;
    padding-top: 32px;
}
.heading {
    @media screen and (min-width: 768px) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
    }
    h1 {
        color: #1f262f;
        font-family: var(--source);
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 0.03em;
        margin: 0.67em 0 0.3em;
        @media screen and (min-width: 768px) {
            font-size: 60px;
        }
    }
    h1 + p {
        font-family: var(--source);
        font-size: 22px;
        line-height: 1;
        font-weight: 600;
        margin: 0.7em 0;
    }
    p {
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 18px;
        line-height: 1.65;
        margin: 0.7em 0;
        a {
            color: var(--primary-link-color);
            font-weight: 700;
            text-decoration: none;
            &:hover {
                color: var(--primary-link-hover-color);
            }
        }
    }
}
.description {
    flex-grow: 1;
}
.image {
    @media screen and (min-width: 768px) {
        margin: 0 63px 0 10px;
    }
    img {
        height: auto;
        max-width: 100%;
        width: 645px;
    }
}
